import { legacy_createStore as createStore } from 'redux';

const initialState = {
  sidebarShow: false,
  theme: 'dark',
  componentTheme: 'dark',
  selectedOption: 'Today', // Add selected option to state
};

const changeState = (state = initialState, action) => {
  switch (action.type) {
    case 'set':
      return { ...state, ...action.payload, theme: state.theme === 'dark' ? 'light' : 'dark' };
      
    case 'toggleTheme':
      return { ...state, theme: state.theme === 'dark' ? 'light' : 'dark' };
      
    case 'setTheme':
      return { ...state, theme: action.theme };
      
    case 'toggleComponentTheme':
      return {
        ...state,
        componentTheme:
          state.componentTheme === 'dark'
            ? 'light'
            : state.componentTheme === 'light'
            ? 'neon'
            : 'dark',
      };
      
    case 'setComponentTheme':
      return { ...state, componentTheme: action.componentTheme };

    case 'setSelectedOption': // New action to update the selected date option
      return { ...state, selectedOption: action.selectedOption };

    default:
      return state;
  }
};

const store = createStore(changeState);
export default store;
